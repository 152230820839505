import React, { Component } from "react";
import { Link } from "react-router-dom";

import { slide as Menu } from "react-burger-menu";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import "react-accessible-accordion/dist/fancy-example.css";
import VideoSoundMobile from '../src/Cate/Calendly'; 

class MobileMenu extends React.Component {
  constructor() {
    super();

    this.state = {
      displayMenu: false,
    };

    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
  }

  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener("click", this.hideDropdownMenu);
    });
  }

  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener("click", this.hideDropdownMenu);
    });
  }

  render() { 
    return (
      <div className="mobile-menu-header">
        <div className="container--">
          <div className="col-md-10--">
            <div className="dropdown mobilemenu-new">
              <Menu>
                <Accordion allowZeroExpanded>
                  <AccordionItem>
                    <a href="/">
                      <AccordionItemHeading>
                        <AccordionItemButton>Home</AccordionItemButton>
                      </AccordionItemHeading>
                    </a>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>Services</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="Menu-underLink">
                      <ul>
   <li><a href="/Branding">Branding</a></li>
   <li><a href="/custom-formulation">Custom Formulation</a></li>
   <li><a href="/private-label">Private Label</a></li>
   <li><a href="/packaging">Packaging</a></li>
   <li><a href="/Manufacturing">Manufacturing</a></li>
   <li><a href="/testing">Testing</a></li>
</ul>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>Products</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="Menu-underLink">
                      <ul>
   <li><a href="/facecare">Face Care</a></li>
   <li><a href="/makeup">Makeup</a></li>
   <li><a href="/perfumes-fine-fragrances">Perfumes</a></li> 
   <li><a href="/Haircare">Hair Care</a></li>
   <li><a href="/babycare">Baby Care</a></li>
   <li><a href="/Bodycare">Body Care</a></li>
   <li><a href="/Mencare">Men Care</a></li>
   <li><a href="/hotel-and-spa-amenities">Hotel Amenities</a></li>
</ul>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <a href="/about-us">
                      <AccordionItemHeading>
                        <AccordionItemButton>
                        About Us
                        </AccordionItemButton>
                      </AccordionItemHeading>
                    </a>
                  </AccordionItem>
                 

                  <AccordionItem>
                    <a href="/Blog">
                      <AccordionItemHeading>
                        <AccordionItemButton>Blog</AccordionItemButton>
                      </AccordionItemHeading>
                    </a>
                  </AccordionItem>
                  <AccordionItem>
                    <a href="/Contact">
                      <AccordionItemHeading>
                        <AccordionItemButton>Contact US</AccordionItemButton>
                      </AccordionItemHeading>
                    </a>
                  </AccordionItem>

                  <AccordionItem>
                    
                      <AccordionItemHeading>
                        <AccordionItemButton>

                        <VideoSoundMobile /> 
                        </AccordionItemButton>
                      </AccordionItemHeading>
                    
                  </AccordionItem>
                </Accordion>
              </Menu>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MobileMenu;
