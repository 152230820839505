import React, { Component } from 'react';
import hotel1 from '../src/lab/hotel1.mp4';
import hotel2 from '../src/lab/hotel2.mp4';
import hotel4 from '../src/lab/hotel4.mp4';
import hotel6 from '../src/lab/hotel6.mp4';

class Hotel extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render() {
        return (
            <div className="hotelpagenew HotelBG fragrances-mainpage"> 
                      <div className="bodycareNewpageVideo">
                            <video autoPlay loop muted>
                            <source src={"https://ik.imagekit.io/deepak/Fine%20fragrance%20banner_XwG-a-vLRT.mp4?updatedAt=1732885287436"} type="video/mp4"/>
                            <source src={"https://ik.imagekit.io/deepak/Fine%20fragrance%20banner_XwG-a-vLRT.mp4?updatedAt=1732885287436"} type="video/ogg"/>          
                            </video>    
                     </div>
                     <div className="hotelamipagestart">
                    <div className="container">
                        <h2>Fine Fragrances Redefined</h2>
                          <div className="row">
                              <div className="col-md-7">
                                    <div className="helteami-left">
                                        <h3>The Global Fragrance <span>Industry</span></h3>
                                        <p>The global fragrance market was valued at approximately $54.52 billion in 2023 and is projected to reach over $77 billion by 2032, growing at a CAGR of 5-6%​ The fragrance market continues to expand with sustainability, personalisation, and wellness driving innovations. Companies investing in ethical practices, cutting-edge technology, and consumer-centric designs are set to lead this vibrant industry.</p>
                                    </div> 
                              </div>
                              <div className="col-md-5">
                                    <video autoPlay loop muted>
                                    <source src={"https://ik.imagekit.io/deepak/fragrance%20video_HHCD5dvNT.mp4?updatedAt=1732885701975"} type="video/mp4"/>
                                    <source src={"https://ik.imagekit.io/deepak/fragrance%20video_HHCD5dvNT.mp4?updatedAt=1732885701975"} type="video/ogg"/>          
                                    </video> 
                              </div>
                          </div>
                    </div>
                    </div>
                        <div className="cateheading">
                        <div className='container'>
                        <h4>Fine Fragrances at Vert Laboratoire</h4>
                        <p>we bring the art of fine fragrance creation to life. Combining expertise with innovative
                        techniques, we offer bespoke solutions for brands seeking to develop signature scents that
                        captivate and inspire.

                        </p>
                        </div>
                        </div>
                    <div className="hotel-categi-section">
                          <div className='container'>
                        <div className="row">
                        <div className="col-md-5 mobile">
                            <img src="https://ik.imagekit.io/deepak/4685d75348683d456250ac8fae28aceb_bF_CGHJLN.jpg?updatedAt=1732885700933"/>
                            </div>
                            <div className="col-md-7">
                                <div className='hotel-categi-section-mm'>
                               <h5>Our Offerings:</h5>
                               <p>Custom Fragrance Development: Tailored to your brand's vision and audience.</p>
                            <p>Fragrance Categories: <span>Eau de Parfum, Eau de Toilette, and more.</span></p>
                            <p>Natural & Sustainable Ingredients: Ethical sourcing aligned with our commitment to sustainability.</p>
                            <h5>Our Process:</h5>
                            <p>Conceptualisation: Collaborate with our perfumers to define the fragrance story.</p>
                            
                                <p>Formulation: Develop unique blends using premium materials.</p>
                                <p>Sampling & Refinement: Create prototypes for testing and perfecting.</p>
                                <p>Production: Ensure high-quality manufacturing with scalability options.</p>


                                </div>


                            </div>
                            <div className="col-md-5 desktop">
                            <img src="https://ik.imagekit.io/deepak/4685d75348683d456250ac8fae28aceb_bF_CGHJLN.jpg?updatedAt=1732885700933"/>
                            </div>
                        </div>
                        </div>
                    </div>
                 
                    <div className="hotelamipagestart spa new">
                    <div className="container">
                          <div className="row">
                          <div className="col-md-4">
                                 <img src='https://ik.imagekit.io/deepak/ad3b704e7fd8b16485419869068a1c98_6wdDA4coyz.jpg?updatedAt=1732885701017'/>
                              </div>
                          <div className="col-md-7">
                                     <h3>Let's craft an
<span>unforgettable scent</span>
<span>that resonates with</span>
<span>your audience!</span></h3>
<p><a href="/contact">Launch Now</a></p>
                              </div>
                              
                            
                          </div>
                    </div>
                    </div>
                    
                    
            </div>
           
        );
    }
}

export default Hotel;