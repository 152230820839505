import React, { Component } from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import Instagram from './Instagram';
import VideoSound from '../src/Cate/VideoSound';
import VideoSoundMobile from '../src/Cate/VideoSoundMobile';
import BRAND from '../src/img/BRAND.jpg';
import HONEST from '../src/img/new.png';
import BRANDBanner from '../src/img/BRANDBanner.jpg';
import Join from '../src/img/Join.mp4';
import Join2 from '../src/img/Join2.mp4';
import Join3 from '../src/img/Join3.mp4';
import Join4 from '../src/img/Join4.mp4';
import Join5 from '../src/img/Join5.mp4';
import THEBRANDNEW from '../src/img/THEBRANDNEW.mp4';
import Tailcruelty1 from '../src/img/Tailcruelty1.gif';
import Tailcruelty2 from '../src/img/Tailcruelty2.gif';
import Tailcruelty3 from '../src/img/Tailcruelty3.gif';
import MobHome from '../src/img/MobHome.jpg';
import MobHome2 from '../src/img/MobHome2.jpg';
import MobHome3 from '../src/img/MobHome3.jpg';
import MobHome5 from '../src/img/MobHome5.jpg';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
  } from 'react-accessible-accordion';
  
  // Demo styles, see 'Styles' section below for some notes on use.
  import 'react-accessible-accordion/dist/fancy-example.css';

class Home2 extends Component {
    render() {
        return (
            <div className="new_home_page"> 
            <div className="container--">         
               <VideoSound/>
               <VideoSoundMobile/>  
            </div>   
               <div className="GREEN-BRAND-FACTORY">        
               <div className="container">
                    <div className="new_home_page_first_section">
                        <div className="row">
                        
                            <div className="col-md-6">
                            <ScrollAnimation offset={20} animatePreScroll={false} initiallyVisible={true} duration={2} animateIn="fadeIn">
                                 <div className="left_home_page_new_first_cont lift">
                                      <h1>CRAFTING SUSTAINABLE BEAUTY BRANDS</h1> 
                                      <div className="right_img_home_pagenewMob">
                                 <div className="right_img_home_pagenew fi">
                                      <img className="img-responsive" src={BRAND}  />   
                                 </div>
                            </div>
                                      <p>We are a team of <span>passionate, conscious creators</span> dedicated to transforming your concepts into nished, market-ready products. As a <span>green manufacturing unit,</span> we excel in understanding your brand’s vision, challenges, and mission, turning your ideas into reality. With endless possibilities, we foster the growth of <span>innovative beauty brands,</span> focusing on high-quality <span>skincare, hair care, makeup</span> and <span>Fine Fragrances</span> solutions.</p>
<p>Our commitment to <span>eco-friendly manufacturing</span> ensures that each product we craft is not just effective but also <span>sustainable,</span> leaving a positive impact on the world.</p>
                                 </div>   
                                 </ScrollAnimation>  
                            </div>
                            <div className="col-md-6 right_img_home_pagenewDesk">
                                 <div className="right_img_home_pagenew fi">
                                      <img className="img-responsive" src={BRAND}  />   
                                 </div>
                            </div>

                        </div>
                        <div className="row">
                        <div className="col-md-6">
                        <div className="left_home_page_new_first_cont NW Mob">
                                    <h3>ETHICAL, HONEST, AND SUSTAINABLE BEAUTY</h3>
                        </div>
                                 <div className="right_img_home_pagenew nd">
                                    <img className="img-responsive" src={HONEST}  />
                                
                                 </div>     
                            </div>

                            <div className="col-md-6">
                                    <ScrollAnimation offset={20} animatePreScroll={false} initiallyVisible={true} duration={2} animateIn="fadeIn">

                                    <div className="left_home_page_new_first_cont NW desktop">
                                    <h3>ETHICAL, HONEST, AND SUSTAINABLE BEAUTY</h3>
                                    <p className="INNOVATING">Innovating Award-Winning, Green Concepts Since 2011</p>

                                    <p><span></span>  For over a decade, Vert Laboratoire has been crafting <span>award-winning formulations</span> using <span>green chemistry</span> to meet the unique needs of numerous brands. As a trusted <span>organic and natural sustainable cosmetics manufacturer,</span> we prioritise zero waste and cruelty-free production, ensuring we measure our product's environmental footprint.</p>
                                    <p><span></span>  Our innovative solutions align with the latest industry trends such as sustainable products using multi functional, active botanicals, transparently ethically sourced, using green methods for extractions. Delivering the right technical support you need for <span>Product Development, Manufacturing, Product Testing, Regulatory</span> & launch of your beauty brand. We take pride in creating high-performance products that reflect our commitment to sustainable ethical and eco-conscious beauty</p>
                                   
                                    </div>

                                    </ScrollAnimation>

                            </div>
                           

                        </div>
                    </div>
                   <div className="bottom_con_all_homepagenew">
                          <p className='bottom_con_all_homepagenewnew'><span>Creating Green, High-Performance Products Without Compromise</span> At Vert Laboratoire, we believe that making <span>eco-friendly cosmetics</span> doesn't mean sacricing ecacy or creativity. We've perfected the science of developing unique, potent formulations that deliver results from the very rst use. As a leading natural and organic cosmetics manufacturer</p>
<p>As a leading natural and organic cosmetics manufacturer, we comply with <span>UK, Kosher, EU, Cosmos, and US FDA standards, producing for a wide range of brands.</span>
</p>
                   </div>

               </div>
               </div>
               <a href="/the-brand">
          <div className="complete_ready_sell">
          <div className="container">
              <h4>Comprehensive Private Label Solutions</h4>
              <p>We can help you develop not just great products in  Makeup, Skin Care, Hair Care and Personal Care. But can also help you with <span>Packaging, Branding, Logo Design, Label, Box Designing, Website, Marketing</span> and so on & so forth.
</p>
<p>
We provide full-service support, from <span>skincare, haircare, makeup, Fine Fragrances and personal care</span> product development to <span>packaging, branding, logo design, label creation, and marketing.</span> Our expertise extends to seamless execution, including <span>website design, SEO integrations,</span> and plugins to optimise your brand's online presence.
</p>

           </div>
          
           </div>
           </a>
           <div className="Main_banner_hb_mid Mob">
                 <img className="img-responsive" src={MobHome3}  /> 
              </div>
        <div className="Main_banner_hb  Desk">
        <ScrollAnimation offset={20} animatePreScroll={false} initiallyVisible={true} duration={2} animateIn="bounceInDown">
        <a href="/Branding">
        <div className="Main_banner_hb_mid">
            <div className="Main_banner_hb_mid_left">
          
              <video autoPlay loop muted>
                        <source src={THEBRANDNEW} type="video/mp4"/>
                        <source src={THEBRANDNEW} type="video/ogg"/>       
              </video>
               
              </div>
              <div className="Main_banner_hb_mid_right">  
                   <img className="img-responsive_brand" src={BRANDBanner}  /> 
              </div>
              </div>
             
              <div className="clear"></div>
              </a>  
             </ScrollAnimation>

        </div>

          <div className="Scrince_come_here_frame">
              <h4>Since <span>2011</span> We Have Enjoyed Working With A Diverse Pool Of Clients</h4>
              <p>They are from different walks of life like Hotels, Spas, Fashion Brands, Retailers, E-Commerce, Startups,<br/> Makeup Artists, Designers, Baby Care companies speciality Mens Brands and we love them all.
</p>
<p>We are a warm <span>Green Brand Factory</span> which is always buzzing with exciting discussion, debates of <br/>upcoming products/brands over a cup of coffee !
</p>
             
                {/* <a href="/Provenience">
              <video autoPlay loop muted>
                        <source src={Join} type="video/mp4"/>
                        <source src={Join} type="video/ogg"/>       
              </video>
                 <img className="img-responsive" src={MobHome2}  />
              </a>
           <a href="/the-brand">
              <video autoPlay loop muted>
                        <source src={Join3} type="video/mp4"/>
                        <source src={Join3} type="video/ogg"/>       
              </video>
              </a>*/} 
              <a href="/how-it-work">
              <img className="img-responsive" src={MobHome}  />
              <video autoPlay loop muted>
                        <source src={Join2} type="video/mp4"/>
                        <source src={Join2} type="video/ogg"/>       
              </video>
              </a>
          </div>


          <div className="reachand_developent_newhomepage">
              <div className="container">
                  <div className="row">
                  <div className="col-md-6">
                            <h4> RESEARCH & <br/> <span>DEVELOPMENT</span></h4>
                            
                      </div>
                      <div className="col-md-6">
                            <ScrollAnimation offset={10} animatePreScroll={false} initiallyVisible={true} duration={2} animateIn="bounceInRight">
                             
                             <div className="reachand_developent_right">
                                  <p> Our state-of-the-art Research & Development Laboratory transforms innovative concepts into high-quality finished products. We specialise in creating custom formulations tailored to your brand's unique needs, combining technical expertise with a deep understanding of market trends. Our team excels in developing sustainable, effective, and cutting-edge products, ensuring your brand stands out in the beauty industry. Whether it's skincare, haircare, or makeup, we craft solutions that align with your vision and commitment to excellence. Let us bring your ideas to life!</p>

                             </div>
                             </ScrollAnimation>
                      </div>
                  </div>
              </div>
          </div>

         

          <ScrollAnimation offset={20} animatePreScroll={false} initiallyVisible={true} duration={2} animateIn="fadeIn">
          
          <div className="join_side_new_home_page">
              <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="join_side_new_home_page-left">
                                 <p>At Vert Laboratoire, we are fully committed to ethical beauty practices. Despite having cutting-edge laboratory facilities, we consciously avoid any testing that involves animal cruelty. We do not <span>conduct or endorse dermatology tests on live animals, nor do we use body parts</span> from the meat industry.</p>
                            </div>
                           
                            <div className="join_side_new_home_page-right desk">
                                 <p>Our philosophy is harmonise with nature, respecting all living beings. Our mission is to create beauty products that are not only eective but also sustainable, ensuring that no one in the value chain—humans, animals, or nature—is harmed. Together, let's make the world more beautiful, responsibly.</p>
                            </div>
                            <div className="join_side_new_mao desk">
                                    
                                <div className="join_side_new_home_page-middle">
                                <img className="img-responsive" src={"https://ik.imagekit.io/deepak/free_wdPaUB835l.jpg"}  /> 
                                </div>
                                <div className="green_side_bottom">
                                <h5>Join The Green Side</h5>
                            </div>

                            </div>
                            
                        </div>
                       
                        <div className="col-md-4">
                                <div className="live_animalvideo">
                                <div className="container---">
                                <video autoPlay loop muted>
                                <source src={Join5} type="video/mp4"/>
                                <source src={Join5} type="video/ogg"/>       
                                </video>
                                  <img className="img-responsive" src={MobHome5}  /> 
                                </div>

                                </div>
                        </div>
                <div className="col-md-12">
                <div className="join_side_new_home_page-right mob">
                <p>Our philosophy is harmonise with nature, respecting all living beings. Our mission is to create beauty products that are not only eective but also sustainable, ensuring that no one in the value chain—humans, animals, or nature—is harmed. Together, let's make the world more beautiful, responsibly.</p>
                </div>
                    <div className="join_side_new_mao mobile">
                        <div className="green_side_bottom">
                        <h5>Join The Green Side</h5>
                        </div>
                        <div className="join_side_new_home_page-middle">
                        <img className="img-responsive" src={"https://ik.imagekit.io/deepak/free_wdPaUB835l.jpg"}  /> 
                        </div>
                    </div>  
                </div>
                        
                    </div>
                    
              </div>
             
          </div>
          </ScrollAnimation>

          <div className="lunch-now-frame">
					<div className="container">
							<div className="top_lunch_now">
								
								<p className='trustedorganic'>Create Your Own Branded Beauty Products  </p>
                                <p >As trusted organic cosmetic manufacturers and natural cosmetics private label experts, we’re here to transform your vision into reality. Our team specialises in helping brands conceptualise, create, design, and amplify their products into a recognised brand. Whether you're looking for sustainable skincare, perfumes ,vegan makeup, or organic beauty products, we have you covered. With extensive experience as private label organic cosmetics manufacturers in the UK, we offer high-quality, eco-friendly formulations to ensure your brand stands out. Let us guide you through every step, from concept to creation, to bring your brand to life.</p>
                                <h4><a href="/contact">LAUNCH NOW</a></h4>
                            </div>
							<div className="lunch_now_buttomframe">
                                  <div className="row">
									  <div className="col-md-4">
										  <div className="speed_frame">
										  <div className="iamg_frame_all new">
                                          <img className="img-responsive" src={Tailcruelty1}  />
                                              </div>
											 <h5>Speed to Market</h5>
											 <p>Launched beauty brands from <br/>  concept stage to Finished goods in <br/> approx 12 weeks straight.</p>
										  </div>
									  </div>
									  <div className="col-md-4">
										  <div className="speed_frame">
										  <div className="iamg_frame_all">
                                          <img className="img-responsive" src={Tailcruelty2}  />
                                        </div>
											 <h5>Sustainable </h5>
											 <p>Harmony is what we pursue. We don't <br/> test on animals and make vegan <br/> products</p>
										  </div>
									  </div>
									  <div className="col-md-4">
										  <div className="speed_frame"> 
											  <div className="iamg_frame_all new">
                                              <img className="img-responsive" src={Tailcruelty3}  />
                                                  </div>
										    
										     <h5>Genesis </h5>
											 <p>We use plant/botanicals sourced <br/> ingredients like butters , oils , flowers , <br/> barks , leaves , spices to create <br/> efficacious products  </p>
										  </div>
									  </div>
								  </div>
							</div>
				    </div>

					</div>

                    <div className="logo-c">
       
	   <img src={"https://ik.imagekit.io/deepak/logo-c_hRMHgPUuah---------.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659267064481"} className="img-responsive" alt="..."/>
	</div>              
<div className="container--">
<Instagram/> 
</div>
<section className="ai-readiness-tab-frame">
            <div className="container">
                <h3>FREQUENTLY ASKED QUESTIONS</h3> 
            <Accordion preExpanded={['a']}>
            <AccordionItem uuid={'a'} >
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What products and services does Vert Laboratoire offer?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <div className="inner-page-mhg">
                       <p>We specialise in creating natural, organic, and cruelty-free cosmetics. Our offerings include private label solutions, product development, sustainable packaging, and branding support for skincare, fragrance oils, Perfumes ,haircare, and makeup.</p>
                      
                    </div>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid={'b'}>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Are your products cruelty-free?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <div className="inner-page-mhg">
                       
                      <p>Yes, all our formulations are cruelty-free. We do not conduct any animal testing and ensure ethical practices throughout our manufacturing process.</p>
                    </div>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid={'c'}>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Can you help create my own beauty brand?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <div className="inner-page-mhg">
                      
                      <p>Absolutely! We provide comprehensive private label services, including product development, packaging design, logo creation, website support, and SEO, to build your brand from the ground up.</p>
                    </div>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid={'D'}>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Are your products sustainable?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <div className="inner-page-mhg">
                      
                       <p>Yes, we are committed to sustainability with zero waste production. Our products are made using eco-friendly ingredients that are free from parabens, plastic beads, and harmful chemicals.</p> 
                    </div>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid={'E'}>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What certifications do you comply with?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <div className="inner-page-mhg">
                      
                       <p>We adhere to global standards, including UK, EU, Cosmos, Kosher, and US FDA certifications, ensuring that our products meet the highest quality and safety requirements.
                       </p> 
                    </div>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid={'F'}>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Can you customise formulations for my brand?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <div className="inner-page-mhg">
                      
                       <p>
                       Yes, we offer tailored solutions to meet the unique needs of your brand, creating bespoke formulations that align with your vision and market requirements.
                        </p> 
                    </div>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid={'G'}>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How long does the private label process take?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <div className="inner-page-mhg">
                      
                       <p>
                       The timeline varies depending on your specific needs. Typically, it can range from a few weeks for product customisation to several months for full brand development, including packaging and marketing.

                        </p> 
                    </div>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid={'H'}>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Do you offer sustainable packaging solutions?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <div className="inner-page-mhg">
                      
                       <p>
                       Yes, we focus on eco-friendly packaging to minimise environmental impact, using recyclable and biodegradable materials wherever possible.
                        </p> 
                    </div>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid={'I'}>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What trends do you incorporate into your products?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <div className="inner-page-mhg">
                      
                       <p>
                       We stay ahead of the curve by incorporating the latest trends, such as sustainable , Toxins free, vegan-friendly formulations, ensuring your brand stays competitive in the beauty industry.
 </p> 
                    </div>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid={'J'}>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How can I get started?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <div className="inner-page-mhg">
                      
                       <p>
                       Contact our team today to discuss your brand vision, and let’s create something extraordinary together!
                        </p> 
                    </div>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
              </div>
            </section>

            </div>
        );
    }
}

export default Home2;